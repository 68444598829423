import React from 'react';
import { Helmet } from 'react-helmet';

import { graphql } from 'gatsby';

import { Layout } from '@app/components';
import { useLocale } from '@app/hooks';

import image from '@app/images/serdar-bora-bayraktaroglu-about.jpg';

import '@app/styles/pages/dr-sbb.scss';

const YOUTUBE_VIDEO_URL =
  '//www.youtube.com/embed/TS6T-r_Ol9I?iv_load_policy=3&amp;enablejsapi=0&amp;wmode=opaque&amp;feature=player_embedded&amp;';

const About: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.About',
  };
  const { t } = useLocale();

  return (
    <Layout>
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <section className="pt-44 pb-9">
        <div className="container mx-auto px-1.5">
          <div className="mb-8">
            <img
              src={image}
              className="mx-auto"
              alt={t('labels.drSerdarBoraBayraktaroglu', SCOPE_OPTIONS)}
            />
          </div>

          <div className="about-box mb-6">
            <div className="about-box__inner">
              <h4 className="text-2xl">{t('labels.drSerdarBoraBayraktaroglu', SCOPE_OPTIONS)}</h4>
            </div>
          </div>

          <div className="text-gray-700 mb-20">
            <p
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: t('descriptions.about', SCOPE_OPTIONS),
              }}
            />
            <p
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: t('descriptions.briefDescription', SCOPE_OPTIONS),
              }}
            />
            <p
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: t('descriptions.info', SCOPE_OPTIONS),
              }}
            />
            <p
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: t('descriptions.hobbyDescription', SCOPE_OPTIONS),
              }}
            />
          </div>

          <div className="youtube-frame relative w-full h-0">
            <iframe
              className="absolute w-full h-full top-0 left-0"
              src={YOUTUBE_VIDEO_URL}
              allowFullScreen
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
